import React from 'react'

export const NotFound = () => {
  return (
    <div className="background">
      <div className="col-12-xs unauthorized">
        <div className="holder col-12-xs mt-x2m-2 mt-md-2">
          <span className='state'>
            404
          </span>
        </div>
        <div className="holder col-12-xs mt-x2m-1 mt-md-2">
          <span className='tag'>
            P&aacute;gina No Encontrada
          </span>
        </div>
        <div className="holder col-12-xs mt-x2m-2 mt-md-2 mb-md-4">
          <span className='reason'>
            La p&aacute;gina que intenta acceder no existe.
          </span>
        </div>
      </div>
    </div>
  )
}
