//Imports
import React from 'react';

//! Consts and styles

export default function Aviso() {
     //? Hooks and functions
     
     
     //* Components rendering
     return (
          <div className='row aviso-cookies pt-2'>
               <h1 className='heading'><strong>TÉRMINOS Y CONDICIONES DE USO DEL SITIO.</strong></h1>
               <p className='texto'>
                    Los términos de uso que a continuación se presentan (en lo sucesivo los TÉRMINOS DE USO) constituyen el acuerdo entre el Instituto Educativo del Noroeste, A.C. (en lo sucesivo CETYS Universidad) y cualquier usuario del SITIO WEB cuya dirección electrónica es: <strong>https://registroapp.cetys.mx/</strong> (en lo sucesivo el SITIO).
               </p>
               <p className='texto'>
                    La utilización del SITIO por parte de cualquier persona, le atribuye la calidad de usuario (en lo sucesivo el USUARIO) y ello implica su adhesión plena e incondicional a estos TÉRMINOS DE USO, en consecuencia es indispensable que el USUARIO los lea previamente y los evalúe de forma cuidadosa, de tal manera que esté consciente de que se sujeta a ellos y a las modificaciones que pudieran sufrir, cada vez que accede al SITIO. Además, se recomienda al USUARIO revisar el <strong>Aviso de Privacidad</strong> de CETYS Universidad, mismo que se entiende aquí agregado y parte integral del presente.
               </p>
               <p className='texto'>
                    Si en cualquier momento el USUARIO no estuviera de acuerdo total o parcialmente con estos TÉRMINOS DE USO, deberá abstenerse inmediatamente de usar el SITIO en cualquiera de sus partes o secciones.
               </p>
               <p className='texto'>
                    El USUARIO está de acuerdo en que en diversas partes del SITIO podrá haber estipulaciones especiales que, según sea el caso, sustituirán, complementarán y/o modificarán los TÉRMINOS DE USO (en lo sucesivo las CONDICIONES PARTICULARES), por tal razón también resulta indispensable que el USUARIO las lea previamente en cada caso y de no estar total o parcialmente de acuerdo con ella o ellas, se abstenga de usar la parte o sección del SITIO regulada bajo esa CONDICIÓN o CONDICIONES PARTICULAR(ES). En caso de que el USUARIO utilice la parte del SITIO regulada por una o más CONDICIONES PARTICULARES, se entenderá que se somete total e incondicionalmente a ellas.
               </p>

               <h1 className='heading'>OBJETO</h1>
               <p className='texto'>
                    A través del SITIO, CETYS Universidad facilita a los USUARIOS el registro de datos de contacto como alumni. El USUARIO reconoce que el uso del SITIO no le implica ningún derecho de propiedad sobre el mismo, cualquiera de sus elementos o CONTENIDOS. CETYS Universidad se reserva el derecho a modificar en cualquier momento y sin aviso previo la presentación, configuración, información, CONTENIDOS y en general cualquier parte o aspecto relacionado directa o indirectamente con el SITIO.               
               </p>

               <h1 className='heading'>ACCESO Y UTILIZACIÓN DEL SITIO</h1>
               <p className='texto'>
                    El acceso y utilización del SITIO por parte de CETYS Universidad tiene carácter gratuito para los USUARIOS.
                    <br/>El USUARIO estará obligado a registrarse en el SITIO únicamente cuando se le solicite y bajo las condiciones que se establezcan por parte de CETYS Universidad.
               </p>

               <h1 className='heading'>OBLIGACIÓN DE HACER USO CORRECTO DEL SITIO Y DE LOS CONTENIDOS.</h1>
               <p className='texto'>
                    El USUARIO se compromete a utilizar el SITIO conforme a las leyes aplicables, a lo dispuesto en estos TÉRMINOS DE USO y con respeto al orden público. El USUARIO se obliga a utilizar el SITIO y cualquier CONTENIDO o aspecto relacionado con él, de una manera en que no lesione derechos o intereses de CETYS Universidad, o de personas vinculadas a éste directa o indirectamente.
                    <br/>EL USUARIO utilizará el SITIO y/o los CONTENIDOS de una manera en la que no los dañe, inutilice, deteriore o menoscabe total o parcialmente.
               </p>

               <h1 className='heading'>USO CORRECTO DE LOS CONTENIDOS</h1>
               <p className='texto'>
                    El USUARIO se obliga a usar los CONTENIDOS y los elementos utilizados en el SITIO de forma diligente, correcta y lícita, y en particular, se compromete a abstenerse de: (a) utilizar los CONTENIDOS de forma, con fines o efectos contrarios a la ley, al orden público y a lo establecido por CETYS Universidad para el uso de este SITIO; (b) copiar, difundir, modificar, reproducir, distribuir o utilizar de cualquier forma con o sin fines de lucro los CONTENIDOS y los elementos utilizados en el SITIO, a menos que se cuente con la autorización expresa y por escrito de CETYS Universidad; (c) modificar o manipular las marcas, logotipos, avisos comerciales, nombres comerciales y signos distintivos en general de CETYS Universidad, del SITIO o de las personas vinculadas directa o indirectamente con CETYS Universidad (salvo que cuente con su autorización por escrito), (d) suprimir, eludir o modificar los CONTENIDOS y los elementos utilizados en el SITIO, así como los dispositivos técnicos de protección, o cualquier mecanismo o procedimiento establecido en el SITIO. CETYS Universidad declara que todos los CONTENIDOS y los elementos utilizados en el SITIO se encuentran debidamente registrados y protegidos bajo las autoridades y leyes correspondientes en México. El USUARIO se obliga a respetar todos los derechos de la Propiedad Intelectual sobre los CONTENIDOS y los elementos utilizados en el SITIO de los que es titular CETYS Universidad.               
               </p>

               <h1 className='heading'>OBLIGACIÓN DE OBSERVAR LAS INSTRUCCIONES</h1>
               <p className='texto'>
                    El USUARIO se compromete a seguir al pie de la letra cualquier instrucción impartida por CETYS Universidad o por personal autorizado por CETYS Universidad que se encuentre relacionado con el SITIO, en relación con cualquier mecanismo o procedimiento establecido para usar el SITIO y los CONTENIDOS. El USUARIO únicamente deberá seguir instrucciones impartidas por CETYS Universidad.               
               </p>
               
               <h1 className='heading'>RESPONSABILIDAD POR DAÑOS Y PERJUICIOS</h1>
               <p className='texto'>
                    El USUARIO es responsable a de cualquier daño y/o perjuicio de cualquier naturaleza que ocasionará por incumplir estos TÉRMINOS DE USO o cualquier normatividad aplicable, por lo que deslinda a CETYS Universidad de toda responsabilidad civil, penal, administrativa o de cualquier otra índole.               
               </p>

               <h1 className='heading'>DATOS DE CARÁCTER PERSONAL</h1>
               <p className='texto'>
                    Para utilizar o gozar de algunos de los CONTENIDOS es necesario que el USUARIO proporcione previamente a CETYS Universidad ciertos datos de carácter personal (en adelante DATOS PERSONALES) que CETYS Universidad podrá administrar de manera automática o no. El USUARIO al acceder al SITIO o a cualquiera de los CONTENIDOS en que los DATOS PERSONALES son requeridos, está autorizando a CETYS Universidad a realizar análisis y estudios con base en ellos. El USUARIO se obliga a proporcionar DATOS PERSONALES verdaderos y fidedignos, y en caso de que deseara no darlos en las partes del SITIO que se soliciten, deberá abstenerse de usar esa o esas partes o secciones del SITIO. En caso de que el USUARIO diera información falsa o confusa, CETYS Universidad podrá negarle el acceso al SITIO y los CONTENIDOS, sin perjuicio de que CETYS Universidad pueda requerirle al USUARIO las indemnizaciones a que hubiere lugar. Por el hecho de proporcionar sus DATOS PERSONALES en el SITIO, el USUARIO está autorizando a CETYS Universidad a dar a conocer a cualquier autoridad competente la información respectiva, en caso de que ésta sea solicitada por los medios jurídicos adecuados. CETYS Universidad no compartirá información personal alguna que haya sido proporcionada, con terceras personas, a menos que sea requerido para proporcionar un servicio o un producto requerido por el USUARIO.
               </p>

               <h1 className='heading'>COOKIES</h1>
               <p className='texto'>
                    Le informamos que la página de Registro APP  cuenta con el sistema habilitado del uso de Cookies; las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de internet específica.  
                    <br/>Las cookies recaban información particular para identificación del usuario. 
                    <br/>En caso de dudas sobre datos personales, favor de consultar el Aviso de Privacidad.
               </p>

               <h1 className='heading'>UTILIZACIÓN DEL SITIO Y DE LOS CONTENIDOS BAJO LA EXCLUSIVA RESPONSABILIDAD DEL USUARIO</h1>
               <p className='texto'>
                    Por el sólo hecho de acceder al SITIO, el USUARIO reconoce y acepta que el uso del mismo y de los CONTENIDOS, es bajo su exclusiva y estricta responsabilidad, por lo que CETYS Universidad no será en ningún momento y bajo ninguna circunstancia, responsable por cualquier desperfecto o problema que se presentará en el equipo de cómputo (hardware) o programas de cómputo (software) que utilice el USUARIO para acceder o navegar en cualquier parte del SITIO.               
               </p>

               <h1 className='heading'>EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</h1>
               <p className='texto'>
                    Exclusión de Garantías y de Responsabilidad por el Funcionamiento del SITIO y de los CONTENIDOS, virus y otros elementos dañinos: CETYS Universidad no controla y no garantiza la ausencia de virus en los CONTENIDOS, ni la ausencia de otros elementos en los CONTENIDOS que puedan producir alteraciones en el sistema informático del USUARIO (software y/o hardware) o en los documentos electrónicos y ficheros almacenados en su sistema informático. El USUARIO acepta ser el único responsable de mantener controles y medidas pertinentes para la prevención de fallas informáticas y virus.
               </p>

               <h1 className='heading'>LICENCIA</h1>
               <p className='texto'>
                    CETYS Universidad autoriza al USUARIO a utilizar el SITIO exclusivamente en los TÉRMINOS Y CONDICIONES aquí expresados, sin que ello implique que concede al USUARIO licencia o autorización alguna o algún tipo de derecho distinto al uso antes mencionado respecto a la “Propiedad Intelectual” de CETYS Universidad. Entendiéndose como “Propiedad Intelectual” todas las marcas registradas y/o usadas en México o en el extranjero por CETYS Universidad, así como todo derecho sobre invenciones (patentadas o no), diseños industriales, modelos de utilidad, secretos comerciales e información confidencial, nombres comerciales, avisos comerciales, reservas de derechos, nombres de dominio, así como todo tipo de derechos patrimoniales sobre obras y creaciones protegidas por derechos de autor y demás formas de propiedad industrial o intelectual reconocida o que lleguen a reconocer las leyes correspondientes.               
               </p>

               <h1 className='heading'>NEGACIÓN Y RETIRO DEL ACCESO AL SITIO Y/O A LOS CONTENIDOS</h1>
               <p className='texto'>
                    CETYS Universidad se reserva el derecho a negar o retirar el acceso al SITIO y/o a los CONTENIDOS en cualquier momento y sin previo aviso, por iniciativa propia o a petición de cualquier persona, a cualquier USUARIO por cualquier motivo, incluyendo sin limitación a aquellos USUARIOS que den un uso indebido al SITIO, a cualquiera de sus partes o secciones o a los CONTENIDOS, o que incumplan total o parcialmente estos TÉRMINOS DE USO o las CONDICIONES PARTICULARES que resulten aplicables.
               </p>

               <h1 className='heading'>DURACIÓN Y TERMINACIÓN</h1>
               <p className='texto'>
                    El SITIO y los CONTENIDOS tienen una duración indefinida. Sin embargo, CETYS Universidad podrá dar por terminada, suspender o interrumpir en cualquier momento y sin necesidad de previo aviso el SITIO y/o cualquiera de los CONTENIDOS.               
               </p>

               <h1 className='heading'>OTRAS DISPOSICIONES</h1>
               <p className='texto'>
                    *Forma. El USUARIO acepta que una versión impresa de estos TÉRMINOS Y CONDICIONES y de cualquier comunicación enviada y/o recibida en forma electrónica será admisible como medio probatorio en cualquier procedimiento judicial y/o administrativo.
                    <br/>*Ley aplicable y jurisdicción. En todo lo relacionado con la interpretación y cumplimiento de lo aquí dispuesto, por el solo hecho de acceder al SITIO los USUARIOS aceptan someterse a las leyes aplicables y a la jurisdicción de los tribunales competentes en la ciudad de Mexicali, Baja California, México, renunciado a cualquier otra jurisdicción que, por razón de sus domicilios presentes o futuros, o por cualquiera otra razón pudiese corresponderles.
               </p>

               <h1 className='heading'>DISPOSICIONES INEFICACES</h1>
               <p className='texto'>
                    En caso de que alguno de los TÉRMINOS Y CONDICIONES aquí expresados sea considerado no ejecutable o se declare nulo o inválido de acuerdo con la legislación aplicable, éste será sustituido por un término o condición que sea válido y que pueda cumplir con mayor rigor el objetivo de la disposición no ejecutable o que haya sido declarada nula o inválida. Los demás términos y condiciones continuarán en plena vigencia.
               </p>

               <h1 className='heading'>DERECHOS</h1>
               <p className='texto'>
                    Cualquier derecho que no se haya conferido expresamente al Usuario en este documento se entiende reservado a CETYS Universidad.               
               </p>
               <span className='version'>
                    <b>Fecha de publicación:</b> 2 de diciembre de 2022.
               </span>
               <span className='version'>
                    <b>Versión:</b> 1.0.
               </span>
          </div>
     )
}