import axios from 'axios';

// export default axios.create({
//      baseURL: "https://ws.cetys.mx/AccesosQA/",
// });

export default axios.create({
     baseURL: "https://ws.cetys.mx/Accesos/",
});

// export default axios.create({
//      baseURL: "http://localhost:55313/",
// });