import React from 'react'

export const Unauthorized = () => {
  return (
    <div className="background">
      <div className="col-12-xs unauthorized">
        <div className="holder col-12-xs mt-x2m-2 mt-md-2">
          <span className='state'>
            401
          </span>
        </div>
        <div className="holder col-12-xs mt-x2m-1 mt-md-2">
          <span className='tag'>
            Acceso No Autorizado
          </span>
        </div>
        <div className="holder col-12-xs mt-x2m-2 mt-md-2 mb-md-4">
          <span className='reason'>
            Falta de permisos. <br/> Solo usuarios registrados pueden acceder.
          </span>
        </div>
      </div>
    </div>
  )
}
