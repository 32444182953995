import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useTimer } from 'react-timer-hook';
import CookieConsent from "react-cookie-consent";
import { yupResolver } from '@hookform/resolvers/yup';
import { emailSchema } from '../schemas/yup.schemas';
import { throwError, duplicateWarning } from '../utils/alerts';
import LoginDataService from '../services/login.service';
import { Link } from 'react-router-dom';

export default function Login() {
    const defTime = new Date();
    defTime.setSeconds(defTime.getSeconds() + 479);
    const link = sessionStorage.getItem("link");
    const [buttonText, setButtonText] = useState(!link ? 'Enviar link': 'Reenviar Link');
    const [valido, setValido] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(emailSchema)
    });

    const { minutes, seconds, isRunning, restart } = useTimer({
        defTime,
        autoStart: false,
        onExpire: () => { setButtonText('Reenviar link'); }
    });

    const onSubmit = data => {
        setLoading(true)
        LoginDataService.sendInvitationEmail(data)
        .then((data) => {
            //console.log(data);
            setValido(true);
            const time = new Date();
            time.setSeconds(time.getSeconds() + 479);
            restart(time);
            sessionStorage.setItem("link", true);
            setLoading(false);
        })
        .catch(e => {
            let message = e.response ? e.response.data : e.message;
            if (message === "u000"){
                duplicateWarning(0);
            } else {
                throwError(message);
            }
            setLoading(false);
        });
    }

    return (
        <div className="background-image">
            <div className='login row col-12-xs'>
                <div className="verification col-11-xs col-10-sm col-8-md col-6-lg col-4-xl">
                    <form className='row mt-2 mb-2' onSubmit={(e) => {
                            handleSubmit(onSubmit)(e)
                            // you will have to catch those error and handle them
                            .catch((e) => {console.log(e);});
                        }} 
                    >
                        {/* <p className='text mb-1'>Llene el siguiente campo con el <b>correo</b> donde recibi&oacute; la <i>invitaci&oacute;n</i> <b>para generar su credencial digital</b>. <br/><br/> Se le enviar&aacute; un <u>link</u> de acceso al mismo.</p> */}
                        {(minutes === 0 && seconds === 0) && (<p className='text mb-1'>Llene el siguiente campo con el <b>correo</b> donde recibi&oacute; la <i>invitaci&oacute;n</i> <b>para generar su credencial digital</b>. <br/><br/> Se le enviar&aacute; un <u>link</u> de acceso al mismo.</p>)}
                        <input {...register("correo")} type="text" className='input mail' placeholder='correo.egresado@ejemplo.com' readOnly={isRunning}/>
                        <span className='error-login'>{errors.correo?.message}</span>
                        <button className='resend mt-1' type='submit' disabled={isRunning || isLoading}>{isLoading ? <div className='button-spinner' id='button-spinner'></div> : buttonText }</button>
                        {(!valido && link) && 
                            <p className='text mt-1'>Ya se habia enviado un <u>link</u> a su <b>correo</b> anteriormente. <br/><br/> Si su <u>link</u> <b><i>expir&oacute;</i></b>, reenv&iacute;e otro.</p>
                        }
                        {valido && (
                            minutes !== 0 || seconds !== 0 ?
                            <p className='text mt-1'>El <u>link</u> ha sido enviado a su <b>correo</b>.<br/><br/>Este <u>link</u> expirar&aacute; en <u>{minutes > 1 ? <b>{minutes} minutos</b> : minutes !== 0 ? <b>{minutes} minuto</b> : <b>menos de 1 minuto</b>}.</u></p>
                            :
                            <p className='text mt-1'>El <u>link</u> ha sido enviado a su <u>correo</u>.<br/><br/>El <u>link</u> ha <u><b>expirado</b></u>.</p>
                        )}
                    </form>
                </div>
            </div>
            <CookieConsent
                location="top"
                buttonText="Aceptar"
                cookieName="aceptacion"
                containerClasses="cookie-container"
                buttonClasses="cookie-button"
                buttonWrapperClasses="cookie-button-wrapper"
                contentClasses="cookie-content"
                overlayStyle={{background:"rgb(0, 0, 0,0.8)"}}
                expires={1}
                sameSite="strict"
                overlay
            >
                Este sitio web utiliza cookies para mejorar la experiencia del usuario.{" "}
                <span>Te invitamos a leer nuestros <Link to="/terminos_y_condiciones" style={{textDecoration:"underline"}}>términos y condiciones</Link>.</span>
            </CookieConsent>
        </div>
    )
}