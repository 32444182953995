import React from 'react';
import { Outlet } from 'react-router-dom';
import logo from '../images/logo.png';


function Navbar() {
  return (
     <main>
          <nav className='navbar col-12-xs'>
               <img src={logo} className="logo" alt='logo'></img>
          </nav>
          <div className="row col-12-xs">
               <Outlet/>
          </div>
     </main>
  )
}

export default Navbar