import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { throwError } from '../utils/alerts';
import { MINUTES_10 } from '../utils/constants';

const UserContext = createContext();

export const UserProvider = ({children}) => {
     const navigate = useNavigate();
     const [cookies, setCookies, removeCookies] = useCookies();

     const login = async (data) => {
          try {
               const res = await AuthService.authUser(data);
               if (res) {

                    setCookies('access', res.data, {
                         path : '/', 
                         expires: new Date(new Date().getTime() + MINUTES_10),
                         //secure: true,
                         sameSite: 'strict',
                    });

                    navigate('/registro');
               } 
          } catch (e) {
               console.log(e);
               let message = e.response ? e.response.data : e.message;
               
               if (message === "u002"){
                    navigate('expired');
               } else {
                    throwError(message);
                    navigate('/login');
               }
          }
     };

     const logout = () => {
          ['access'].forEach(obj => removeCookies(obj));
     }

     const value = useMemo(
          () => ({
               cookies,
               login,
               logout
          }), [cookies]
     );

     return (
          <UserContext.Provider value={value}>
               {children}
          </UserContext.Provider>
     );
};

export function useAuth() {
     return useContext(UserContext);
};


