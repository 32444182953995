import React from 'react';
import { Link } from 'react-router-dom';

export default function Expired() {

        return (
            <div className="background-image">
                <div className='login col-12-xs'>
                    <div className="verification row col-11-xs col-10-sm col-8-md col-6-lg col-4-xl">
                        <p className='notice mt-2 mb-1'>El link que utilizó ya expiro.</p>
                        <p className='text mb-1'>Vuelva a solicitar otro para continuar con su registro.</p>
                        <Link className='resend mb-2' to='/login'>Solicitar otro</Link>
                    </div>
                </div>
            </div>
        )
}