import React, { useEffect } from 'react';
import Footer from '../components/Footer';
import { useAuth } from '../hooks/useAuth';
import alumniGrad from '../images/bg_middle.jpeg';

function Resultado() {
  const { logout } = useAuth();
  useEffect(()=>{

    logout();
   
  },[])

  return (
    <>
     <div className="col-12-xs col-5-md" >
        <img src={alumniGrad} alt="alumni" className='image'/>
      </div>
      <div className='row col-12-xs col-7-md'>
        <div className='content container center'>
          <div className='result col-12-xs'>
            <div className='start col-12-xs'>
              <span className='head'>Solicitud Enviada</span>
            </div>
            <div className='col-12-xs'>
              <div className='figura-top center'/>
            </div>
            <div className='response col-12-xs mt-md-1'>
              <span>Su solicitud ha sido registrada</span>
            </div>
            <div className="description padded col-12-xs">
              <span>
                En este momento se est&aacute; dando de alta su cuenta en la aplicaci&oacute;n m&oacute;vil.
                <br/><br/>
                Una vez terminado este proceso, recibir&aacute; un correo con su <span className='bolics'>usuario</span> y <span className='bolics'>contraseña</span> para acceder a su cuenta dentro de la CETYS App.
                <br/><br/>
                La llegada del correo puede demorar. Agradecemos su paciencia.
                <br/><br/>
                Puede cerrar esta p&aacute;gina si lo desea.
              </span>
            </div>
          </div>
          <Footer/>
        </div>
      </div>  
    </>
      
  );
}

export default Resultado