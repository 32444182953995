import Swal from 'sweetalert2';

export function throwError(codigo = 'n000') {

     if(codigo === 'r000') {
          Swal.fire({
               title: 'Correo incorrecto',
               html: '<font size="+2"><b><i>C&oacute;digo: ' + codigo + '</b></i></font><br/><br/>El correo proporcionado es incorrecto o no está disponible para el registro.<br/><br/>Para brindarle apoyo, env&iacute;e un correo con el <i><strong>c&oacute;digo</strong></i> mostrado anteriormente a <b><a href="mailto:soportemicampus@cetys.mx" rel="noreferrer noopener" class="smail">soportemicampus@cetys.mx</a></b>.',
               color: '#000000',
               icon: 'warning',
               confirmButtonText: 'Cerrar',
               allowOutsideClick: false,
          }); 
     } else {
          Swal.fire({
               title: '¡Error de sistema!',
               html: '<font size="+2"><b><i>C&oacute;digo: ' + codigo + '</b></i></font><br/><br/>Para brindarle apoyo, env&iacute;e un correo con el c&oacute;digo mostrado a <b><a href="mailto:soportemicampus@cetys.mx?subject=Petición%20de%20Soporte%20-%20Registro%20App:%20Error%20'+codigo+'" rel="noreferrer noopener" class="smail">soportemicampus@cetys.mx</a></b>.',
               color: '#000000',
               icon: 'error',
               confirmButtonText: 'Cerrar',
               allowOutsideClick: false,
          }); 
     }

}

export function duplicateWarning(option) {
     if (option === 0){
          Swal.fire({
               title: '¡Correo ya registrado!',
               html: '<font size="+2"><b><i>Encontramos una cuenta ya registrada con el correo proporcionado</b></i></font><br/><br/>Si pertenece de la generación 2019 en adelante, intente acceder con su cuenta de "Alumno" en la aplicación. <br/><br/>En caso contrario, comun&iacute;quese con el &aacute;rea de soporte a trav&eacute;s de <b><a href="mailto:soportemicampus@cetys.mx?subject=Petición%20de%20Soporte%20-%20Registro%20App:%20Error%20u000" rel="noreferrer noopener" class="smail" >soportemicampus@cetys.mx</a><b>.',
               color: '#000000',
               icon: 'warning',
               confirmButtonText: 'Cerrar',
               allowOutsideClick: false,
          }); 
     } else {
          Swal.fire({
               title: '¡Matrícula ya registrada!',
               html: '<font size="+2"><b><i>Encontramos una cuenta ya registrada con la matrícula proporcionada</b></i></font><br/><br/>Si pertenece de la generación 2019 en adelante, intente acceder con su cuenta de "Alumno" en la aplicación. <br/><br/>En caso contrario, comun&iacute;quese con el &aacute;rea de soporte a trav&eacute;s de <b><a href="mailto:soportemicampus@cetys.mx?subject=Petición%20de%20Soporte%20-%20Registro%20App:%20Error%20u000" rel="noreferrer noopener" class="smail" >soportemicampus@cetys.mx</a><b>.',
               color: '#000000',
               icon: 'warning',
               confirmButtonText: 'Cerrar',
               allowOutsideClick: false,
          }); 
     }
}

export function captchaWarning() {
     Swal.fire({
          title: '¡La prueba de reCAPTCHA expiró!',
          html: 'Realice la prueba de reCAPTCHA nuevamente.',
          color: '#000000',
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          allowOutsideClick: false,
     }); 
}

export function expiredWarning() {
     Swal.fire({
          title: 'Su sesión ha expirado',
          html: 'El tiempo disponible de su sesión ha terminado. Por favor vuelva a solicitar un link de acceso para reanudar el proceso de registro.',
          color: '#000000',
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          allowOutsideClick: false,
     }); 
}

export function existsWarning() {
     Swal.fire({
          title: 'Usted ya tiene una cuenta en la CETYS App.',
          html: 'La matrícula que utilizó para crear una cuenta nueva dentro de la aplicación móvil, ya se encuentra registrada con otra cuenta anterior. <br/><br/>Si pertenece de la generación 2019 en adelante, intente acceder con su cuenta de "Alumno" en la aplicación. <br/><br/>Si no recuerda su usuario o contraseña de esta última, comuniquese con el área de soporte a través de <b><a href="mailto:soportemicampus@cetys.mx?subject=Petición%20de%20Soporte%20-%20Registro%20App:%20Error%20a001" rel="noreferrer noopener" class="smail" >soportemicampus@cetys.mx</a><b>.',
          color: '#000000',
          icon: 'warning',
          confirmButtonText: 'Cerrar',
          allowOutsideClick: false,
     }); 
}