//Imports
import React from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

//! Consts and styles

export default function Auth() {
     //? Hooks and functions
     const [searchParams] = useSearchParams();
     const { login } = useAuth();
     
     //const correo = searchParams.get('correo') ?? "";
     const state = searchParams.get('state') ?? "";

     useEffect(()=>{
          login({state: state});
     },[]);

     //* Components rendering
     return (
          <div className='auth row'>
               <div className="spinner">
                    <span className="span spinner-inner-1"></span>
                    <span className="span spinner-inner-2"></span>
                    <span className="span spinner-inner-3"></span>
               </div>
               <div className='spinner-text'>
                    <span className='waiter'>Por favor, espere un momento...</span>
               </div>
          </div>
     )
}