import http from "./http-commons";

class FormsDataService {
     getEgresado() {
          return http.get("Alumni/v1/GetEgresado", {withCredentials: true});
     }

     sendForms(data) {
          return http.post("Alumni/v1/FormValidation", data, {withCredentials: true});
     }
}

export default new FormsDataService();
