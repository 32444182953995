import React from 'react'

export default function Footer() {
     const subject = "Petición de soporte: Solicitud de cuenta de egresado - Código: ug00";
     const body = "Saludos,\n\n Actualmente me encuentro llenando el formulario de solicitud para dar de alta mi cuenta de egresado.\n\n Le hago envío de este correo debido a que (Describir problema)"
   
     return (
          <div className='footer col-12-xs mt-1 mb-1 justify-center'>
               <div className='col-12-xs end'>
                    <div className='figura-bottom'></div>
               </div>
               <div>
                    <span className="col-12-xs soporte">Soporte CETYS - <a className='smail' href={"mailto:soportemicampus@cetys.mx?subject="+encodeURIComponent(subject)+"&body="+encodeURIComponent(body)} rel="noreferrer noopener" target="_blank">soportemicampus@cetys.mx</a> </span>
               </div>
          </div>
     );
}