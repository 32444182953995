import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Forms from './pages/Forms';
import Resultado from './pages/Resultado';
//import Soporte from './pages/Soporte';
import Login from './pages/Login';
import { Unauthorized } from './pages/Unauthorized';
import { ProtectRoutes } from './hooks/useProtect';
import { NotFound } from './pages/NotFound';
import Expired from './pages/Expired';
import Auth from './pages/Auth';
import Aviso from './pages/Aviso';

function App() {
  return (
    // <Routes>
    //   <Route path='/' element={ <Navbar/> }>
    //     <Route index element={ <Forms/> } />
    //     {/* <Route path='soporte' element={ <Soporte/> } /> */}
    //     <Route path='resultado' element={ <Resultado/> } />
    //     <Route path="*" element={ <NotFound /> } />
    //   </Route>
    // </Routes>

    <Routes>
      <Route path='/' element={ <Navbar /> } >
        <Route index element={ <Navigate to='registro' exact /> } />
        <Route path='login' element={ <Login /> } />
        <Route path='expired' element={ <Expired /> } />
        <Route path='unauthorized' element={ <Unauthorized /> } />
        <Route path='auth' element={<Auth />} />
        <Route path='resultado' element={ <Resultado /> } />
        <Route path='terminos_y_condiciones' element={ <Aviso /> } />

        <Route element={<ProtectRoutes/>} >
            <Route path='registro' element={ <Forms /> } />
        </Route>

        <Route path="*" element={ <NotFound /> } />
      </Route>
    </Routes> 
  );
}

export default App;
