import * as Yup from 'yup';

export const solicitudSchema = Yup.object().shape({
     correo: Yup.string().email('- El correo electrónico debe ser válido').required('- Campo obligatorio'),
     nombres: Yup.string().required('- Campo obligatorio').matches(/^[a-zA-ZÀ-ÿ\s_.]*$/,'- Solo insertar letras'),
     apellidos: Yup.string().required('- Campo obligatorio').matches(/^[a-zA-ZÀ-ÿ\s_.]*$/,'- Solo insertar letras'),
     matricula: Yup.string().required('- Campo obligatorio').matches(/^[mteMTE](\d*)$/,'- Seleccione una matrícula').min(7, '- Deben de ser 7 números').max(7, '- Deben de ser 7 números'),
     campus: Yup.string(), //.required('- Campo obligatorio').oneOf(['Mexicali','Tijuana','Ensenada'],'- No es un campus válido'),
     captcha: Yup.string().required("- Por favor realice la prueba de reCAPTCHA").typeError("- Por favor realice la prueba de reCAPTCHA"),
     protesta: Yup.boolean().oneOf([true]),
     aviso: Yup.boolean().oneOf([true]),
}).required();

export const emailSchema = Yup.object().shape({
     correo: Yup.string().email('- El correo electrónico debe ser válido').required('- Inserte el correo solicitado'),
}).required();

export const pinSchema = Yup.object().shape({
     pin: Yup.string().required('- Inserte pin').matches(/^[a-zA-Z0-9]*$/,'- Solo letras y números').min(6, '- Deben de ser 6 números').max(6, '- Deben de ser 6 números'),
}).required();