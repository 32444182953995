import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { solicitudSchema } from '../schemas/yup.schemas';
import { throwError, duplicateWarning, captchaWarning, existsWarning } from '../utils/alerts';
import FormsDataService from '../services/form.service';
import Footer from '../components/Footer';
import alumniGrad from '../images/bg_middle.jpeg';


function Forms() {
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const captchaRef = useRef(null);
  const [first, setFirst] = useState(true);
  const [matriculas, setMatriculas] = useState(["Seleccione matrícula"]);
  const [isLoading, setIsLoading] = useState(true);

  const initialValues = {
    email: '',
    nombres: '',
    apellidos: '',
  };

  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({
    resolver: yupResolver(solicitudSchema)
  });
  
  const matricula = useWatch({
    control,
    name: 'matricula'
  });

  useEffect(() => {
    if (first){
      FormsDataService.getEgresado()
      .then((response) => {
        console.log(response);
        
        if (!response.data){
          throwError("n001");
          return;
        }

        let data = response.data;

        setValue('correo', data.email );
        setValue('nombres', data.nombres );
        setValue('apellidos', data.apellidos );
        setMatriculas([...matriculas, ...data.matriculas.trim().split(',')]);

        setFirst(false);
        setIsLoading(false);
      })
      .catch((e) => {
        //console.log(e);
        error(e,0);
      });
    } else if (matricula) {
      let inicial = matricula.charAt(0);

      switch (inicial){
        case 'm':
          setValue('campus', 'Mexicali' );
          break;
        case 't':
          setValue('campus', 'Tijuana');
          break;
        case 'e':
          setValue('campus', 'Ensenada');
          break; 
        default:
          setValue('campus', '');
          break;
      }
    }
  },[matricula]);

  // Forms fetch post
  const onSubmit = data => {
    setIsLoading(true);
    FormsDataService.sendForms(data)
    .then((data) => {
      //console.log(data);
      captchaRef.current.reset();
      setIsLoading(false);
      navigate('/resultado');
    })
    .catch(e => {
      //console.log(e);
      error(e,1);
      setIsLoading(false);
    });
  }

  const onVerifyCaptcha = (token) => {
    setValue('captcha', token);
  };

  const error = (e ,option) => {
    captchaRef.current.reset();
    setValue('captcha', null);
  
    let message;
    
    if (e.response.status === 401) {
      message = e.response.status;
    } else {
      message = e.response ? e.response.data : e.message;
    }
  
    if (message === "u000") {
      duplicateWarning(option);
    } else if (message === "u004") {
      captchaWarning();
    } else if (message === "a001") {
      existsWarning();
      navigate('/login');
    } else {
      throwError(message);
      navigate('/login');
    }
  }
  
  return (
    <>
      <div className="col-12-xs col-5-md" >
        <img src={alumniGrad} alt="alumni" className='image'/>
      </div>
      <div className='row col-12-xs col-7-md'>
        <div className='content'>
          <div className='heading row col-12-xs mt-md-2'>
            <span className='title'>Reg&iacute;strate en la App M&oacute;vil</span>
            <span className="help">Para generar tu credencial digital CETYS Alumni, llene el siguiente formulario y acepte los avisos para registrar su cuenta. Si la información que aparece no es suya, por favor contácte a soporte y detenga el registro. </span>
            <div className='figura-top'></div>
          </div>

          <form onSubmit={(e) => {
              handleSubmit(onSubmit)(e)
              // you will have to catch those error and handle them
              .catch((e) => {
                error(e,0);
              });
            }} 
            className="forms col-12-xs"
          >
            <div className="col-12-xs">
              <label className='label' htmlFor="correo">Correo El&eacute;ctr&oacute;nico*</label>
              <input {...register("correo")} defaultValue={initialValues.email} type="email" className="email" readOnly />
              <span className='error'>{errors.correo?.message}</span>
            </div>

            <div className="col-12-xs col-6-md">
              <label className='label' htmlFor="nombre">Nombre(s)*</label>
              <input {...register("nombres")} defaultValue={initialValues.nombres} type="text" className="prefilled" readOnly/>
              <span className='error'>{errors.nombres?.message}</span>
            </div>

            <div className="col-12-xs col-6-md">
              <label className='label' htmlFor="apellidos">Apellidos*</label>
              <input {...register("apellidos")} defaultValue={initialValues.apellidos} type="text" className="prefilled" readOnly/>
              <span className='error'>{errors.apellidos?.message}</span>
            </div>

            <div className="col-12-xs col-6-md">
              <label className='label' htmlFor="matricula">Matr&iacute;cula CETYS* <span className='hovertext' data-hover='Si cuentas con más de una matrícula, elige para este campo la de Profesional.'>&nbsp;&#9432;</span></label>
                <div className="select">
                  <select {...register("matricula")} defaultValue="Seleccione matrícula" type="text" id='standard-select'>
                    <option value="" disabled hidden default>Seleccione matr&iacute;cula</option>
                    {
                      matriculas.map((matricula, key) => {
                        if(matricula){
                          return (<option value={matricula} key={key}>{matricula}</option>)
                        }
                        return (<></>);
                      })
                    }
                  </select>
                  <span className="focus"></span>
                </div>
                <span></span>
                <span className='error'>{errors.matricula?.message}</span>
            </div>

            <div className="col-12-xs col-6-md">
              <label className='label' htmlFor="campus">Campus*</label>
              <input {...register("campus")} type="text" className='prefilled' readOnly/>
              <span className='error'>{errors.campus?.message}</span>
            </div>

            <div className="captcha col-12-xs mt-1 justify-center">
              <ReCAPTCHA {...register("captcha")} theme='light' sitekey="6LeWy8AiAAAAALaGqCaGXjY65SY2uYPELpBwuCJ1" ref={captchaRef} onChange={onVerifyCaptcha} lang='es-419'/>
            </div>

            <div className="captcha col-12-xs mb-1 justify-center">
              <span className='error'>{errors.captcha?.message}</span>
            </div>

            <div className="col-12-xs">
              <label className='aviso' htmlFor="protesta">
                <input {...register("protesta")} type="checkbox" /> <span>Declaro bajo protesta de decir verdad que la informaci&oacute;n proporcionada es ver&iacute;dica.</span>
              </label>
            </div>

            <div className="col-12-xs">
              <label className='aviso' htmlFor="aviso">
                <input {...register("aviso")} type="checkbox" /> <span>He leido y entendido el aviso de privacidad publicado en la p&aacute;gina <a href='https://www.cetys.mx/aviso-de-privacidad' className='smail' rel="noreferrer noopener" target="_blank">www.cetys.mx/aviso-de-privacidad</a></span>
              </label>
              {errors.protesta?.message || errors.aviso?.message ? 
                <div className="captcha col-10-xs justify-center">
                  <span className='error'>- Debe de aceptar todos los t&eacute;rminos</span>
                </div> 
                : <></>}
            </div>
            <div className="col-12-xs">
              {/* <input type='submit' value="regístrate" className='button' disabled={isLoading}/> */}
              <button type='submit' className='button' disabled={isLoading}>
                {isLoading ? <div className='button-forms-spinner' id='button-forms-spinner'></div> : "Regístrate"}
              </button>
            </div>
          </form>
          <Footer/>
        </div>
      </div>
    </>
  )
}



export default Forms