import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import jwt_decode from "jwt-decode";
import { expiredWarning, throwError } from "../utils/alerts";

export function ProtectRoutes() {
     const navigate = useNavigate();
     const { cookies, logout } = useAuth();

     if (cookies.access){
          const token = jwt_decode(cookies.access);
          const date = new Date().getTime()/1000;
          
          if (token.iss === 'https://ws.cetys.mx' && token.aud === 'https://registroapp.cetys.mx' && date > token.nbf && date < token.exp ) {
               return <Outlet/> 
          }
     
          if (date > token.exp){
               expiredWarning();
               logout();
               navigate('/login');
          }

          if (token.aud !== 'https://registroapp.cetys.mx' || token.iss !== 'https://ws.cetys.mx'){
               throwError('401');
               logout();
               navigate('/login');
          }
     } else {
          return <Navigate to='/login' exact />;
     }
};
